@import '@crystal-eyes/constants.scss';

.container {
  display: flex;
  position: relative;
  align-items: center;
  flex-wrap: nowrap;
  transition: all 0.3s;
  margin: 8px 0;
  cursor: pointer;

  &.noMargin {
    margin: 0;
  }

  &:hover {
    &.hoverState {
      .checkbox {
        border: 1px solid rgba($action-blue, 0.4);
        background-color: rgba($action-blue, 0.4);
        box-shadow: 0 0 2px $material-08;
        &.checked {
          background-color: rgba($action-blue, 0.8);
        }
      }
    }
  }

  &:active {
    &.hoverState {
      .checkbox {
        background-color: $action-blue;
      }
    }
  }
}

.checkbox {
  width: 18px;
  min-width: 18px;
  height: 18px;
  border: 1px solid $material-34;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  background-color: #fff;
  margin-right: 16px;
  margin-top: 6px;
  transition: all 0.1s;
  &.noMargin {
    margin-top: 0;
    margin-right: 0;
  }

  &.checked {
    border: 1px solid $action-blue;
    background-color: $action-blue;
  }

  &.loading {
    background-color: transparent !important;
    border: 1px solid transparent !important;
  }

  img {
    width: 100%;
  }
}

.label {
  font-size: 16px;
  margin-top: 6px;

  &.noMargin {
    margin-top: 0;
    margin-left: 12px;
  }

  &.bigLabel {
    margin-left: 12px;
    color: $material-87;
  }
}

.input {
  position: absolute;
  z-index: -1;
  opacity: 0;

  &:focus + .checkbox {
    border-color: $blue;
  }
}

.tooltip {
  margin-bottom: -8px;
}
