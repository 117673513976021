.banner {
  position: fixed;
  top: 4rem;
  right: 1rem;
  z-index: 40;
  background-color: white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.08);
  border: 1px solid #e5e7eb;
  border-radius: 0.75rem;
  padding: 0.75rem 1.25rem;
  display: flex;
  animation: slideDown 0.3s ease-out;
  align-items: center;
}
.content {
  display: flex;
  align-items: center;
}

.message {
  font-size: 0.9375rem;
  line-height: 1.5;
  color: #374151;
  display: flex;
  align-items: center;
  margin-right: 1rem;
  gap: 0.25rem;
}

.closeButton {
  position: absolute;
  right: 2px;
  top: 10px;
  border: none;
  background: none;
  padding: 0.25rem;
  cursor: pointer;
  border-radius: 50%;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  svg {
    width: 1.25rem;
    height: 1.25rem;
    color: #6b7280;
    transition: color 0.2s ease;
  }

  &:hover svg {
    color: #374151;
  }
}

@keyframes slideDown {
  from {
    transform: translateY(-50%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
